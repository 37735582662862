<template>
    <section class="section section-shaped section-lg my-0">
        <div class="shape shape-style-1 shape-green-dark">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container pt-lg-md">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <card type="secondary" shadow
                          header-classes="bg-white pb-5"
                          body-classes="px-lg-5 py-lg-5"
                          class="border-0">
                        <template>
                            <div ref="cookieScript"></div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "cookie-policy",
    mounted() {
        let cookieBotScript = document.createElement('script');
        cookieBotScript.setAttribute('src', "https://consent.cookiebot.com/81931ce4-a970-4834-bc0f-4e192803f278/cd.js");
        cookieBotScript.id = "CookieDeclaration";
        cookieBotScript.async = true;
        cookieBotScript.type = "text/javascript";
        this.$refs.cookieScript.insertAdjacentElement('beforeend', cookieBotScript);
    }
};
</script>
<style>
</style>
