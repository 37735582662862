import Vue from 'vue'
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    webPreferences: {
      lang: 'en'
    },
  },
  mutations: {
    setLang(state, lang) {
      state.webPreferences.lang = lang;
    }
  },
  getters : {
    getLang: state => state.webPreferences.lang
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 365, secure: true, sameSite: "strict" }),
        removeItem: (key) => Cookies.remove(key),
      },
      paths: ['webPreferences']
    })
  ]
});

export default store
