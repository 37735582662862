<template>
    <div>
        <div class="position-relative">
            <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-green-dark shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-10">
                                <h1 class="display-3 text-white">NDF Solutions</h1>
                                <p class="lead text-white"><i>Bringing innovation in the automotive industry</i></p>
                                <br><br><br><br>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <section class="section  mt--300">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">

                            <div class="col-lg-4" style="display: flex">
                                <card class="border-0" hover shadow body-classes="pt-5 pb-3">

                                    <div class="card-header-pills mt--100">
                                        <img src="./../assets/img/landing/greenEnergy.jpg" class="card-img img-center shadow" style="border-radius: 10px">
                                    </div>

                                    <h6 class="text-success text-uppercase text-center mt-3">EV and Smart Mobility</h6>
                                    <p class="description mt-3 text-justify">
                                        Advanced energy distribution stations for Electric Vehicles (EV) acting as Blockchain nodes. An innovative Smart and Green ecosystem will be promoted, to incentivize the adoption of EVs and the creation of distribution networks.
                                    </p>

                                </card>
                            </div>

<!--                            <div class="col-lg-4">-->
<!--                                <card class="border-0" hover shadow body-classes="py-5">-->
<!--                                    <icon name="ni ni-istanbul" type="success" rounded class="mb-4">-->
<!--                                    </icon>-->
<!--                                    <h6 class="text-success text-uppercase">Automotive</h6>-->
<!--                                    <p class="description mt-3">Argon is a great free UI package based on Bootstrap-->
<!--                                        4 that includes the most important components and features.</p>-->
<!--                                    <div>-->
<!--                                        <badge type="success" rounded>business</badge>-->
<!--                                        <badge type="success" rounded>vision</badge>-->
<!--                                        <badge type="success" rounded>success</badge>-->
<!--                                    </div>-->
<!--                                    <base-button tag="a" href="#" type="success" class="mt-4">-->
<!--                                        Learn more-->
<!--                                    </base-button>-->
<!--                                </card>-->
<!--                            </div>-->

                            <div class="col-lg-4" style="display: flex">
                                <card class="border-0" hover shadow body-classes="pt-5 pb-3">
                                    <div class="card-header-pills mt--100">
                                        <img src="./../assets/img/landing/supercar.jpg"
                                             class="card-img img-center  shadow "  style="border-radius: 10px"
                                        >
                                    </div>
                                    <h6 class="text-warning text-uppercase text-center mt-3">Super Cars</h6>
                                    <p class="description mt-3 text-justify">
                                        Realize your dreams of owning a work of art! Advanced distribution and tokenization systems, also based on the Blockchain technology, will be realized to let you own portions of a super car!
                                    </p>

                                </card>
                            </div>

<!--                            <div class="col-lg-4">-->
<!--                                <card class="border-0" hover shadow body-classes="py-5">-->
<!--                                    <icon name="ni ni-planet" type="warning" rounded class="mb-4">-->
<!--                                    </icon>-->
<!--                                    <h6 class="text-warning text-uppercase">Super Cars</h6>-->
<!--                                    <p class="description mt-3">Argon is a great free UI package based on Bootstrap-->
<!--                                        4 that includes the most important components and features.</p>-->
<!--                                    <div>-->
<!--                                        <badge type="warning" rounded>marketing</badge>-->
<!--                                        <badge type="warning" rounded>product</badge>-->
<!--                                        <badge type="warning" rounded>launch</badge>-->
<!--                                    </div>-->
<!--                                    <base-button tag="a" href="#" type="warning" class="mt-4">-->
<!--                                        Learn more-->
<!--                                    </base-button>-->
<!--                                </card>-->
<!--                            </div>-->

                            <div class="col-lg-4" style="display: flex">
                                <card class="border-0" hover shadow body-classes="pt-5 pb-3">
                                    <div class="card-header-pills mt--100">
                                        <img src="./../assets/img/landing/vintageCar.png"
                                             class="card-img img-center shadow "  style="border-radius: 10px"
                                        >
                                    </div>
                                    <h6 class="text-primary text-uppercase text-center mt-3">Historic Cars</h6>
                                    <p class="description mt-3 text-justify">
                                        Not only futuristic styles, but also high-value historic cars! NDF will allow you to own a piece of history through an advanced tokenization system based on the Blockchain technology!
                                    </p>

                                </card>
                            </div>

<!--                            <div class="col-lg-4">-->
<!--                                <card class="border-0" hover shadow body-classes="py-5">-->
<!--                                    <icon name="ni ni-check-bold" type="primary" rounded class="mb-4">-->
<!--                                    </icon>-->
<!--                                    <h6 class="text-primary text-uppercase">E-drive</h6>-->
<!--                                    <p class="description mt-3">Argon is a great free UI package based on Bootstrap 4-->
<!--                                        that includes the most important components and features.</p>-->
<!--                                    <div>-->
<!--                                        <badge type="primary" rounded>design</badge>-->
<!--                                        <badge type="primary" rounded>system</badge>-->
<!--                                        <badge type="primary" rounded>creative</badge>-->
<!--                                    </div>-->
<!--                                    <base-button tag="a" href="#" type="primary" class="mt-4">-->
<!--                                        Learn more-->
<!--                                    </base-button>-->
<!--                                </card>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="section section-lg pt-4 pb-0">
            <div class="container">
                <div class="row justify-content-center text-center mb-3">
                    <div class="col-lg-8">
                        <h2 class="display-3">Founders</h2>

                    </div>
                </div>
                <div class="row">

                    <div class="col-md-6 mb-5 mb-lg-0 mx-auto">
                        <div class="px-4">
                            <div class="row">
                                <div class="col-6">
                                    <img src="./../assets/img/team/Mario.jpg"
                                         class=" img-center img-fluid shadow shadow-lg--hover"
                                         style="width: 150px;">
                                </div>
                                <div class="col-6">
                                    <div class="pt-md-4 text-center">
                                        <h5 class="title">
                                            <span class="d-block mb-1">Mario Ferrari</span>
                                            <small class="h6 text-muted">Corporate Manager</small>
                                        </h5>
                                        <p class="description mt-4">
                                            Computer Scientist, Programmer at Hewlett-Packard, Serial Entrepreneur
                                        </p>
<!--                                        <div class="mt-3">-->
                                            <!--                                    <base-button tag="a" href="#" type="primary" icon="fa fa-twitter" rounded icon-only></base-button>-->
                                            <!--                                    <base-button tag="a" href="#" type="primary" icon="fa fa-facebook" rounded icon-only></base-button>-->
                                            <!--                                    <base-button tag="a" href="#" type="primary" icon="fa fa-dribbble" rounded icon-only></base-button>-->
<!--                                        </div>-->
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>


                    <div class="col-md-6 mb-5 mb-lg-0 mx-auto pt-100">
                        <div class="px-4">
                            <div class="row">

                                <div class="col-6">
                                    <div class="pt-md-4 text-center">
                                        <h5 class="title">
                                            <span class="d-block mb-1">Andrea Pezzato</span>
                                            <small class="h6 text-muted">Project Manager</small>
                                        </h5>

                                        <p class="description mt-4">
                                            R&D manager, strong experience in the Blockchain and Crowdfunding sector
                                        </p>
<!--                                        <div class="mt-3">-->
                                            <!--                                    <base-button tag="a" href="#" type="success" icon="fa fa-twitter" rounded-->
                                            <!--                                                 icon-only></base-button>-->
                                            <!--                                    <base-button tag="a" href="#" type="success" icon="fa fa-facebook" rounded-->
                                            <!--                                                 icon-only></base-button>-->
                                            <!--                                    <base-button tag="a" href="#" type="success" icon="fa fa-dribbble" rounded-->
                                            <!--                                                 icon-only></base-button>-->
<!--                                        </div>-->
                                    </div>

                                </div>

                                <div class="col-6">
                                    <img src="./../assets/img/team/Pezzato.jpeg"
                                         class=" img-center img-fluid shadow shadow-lg--hover"
                                         style="width: 150px;">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
export default {
  name: "home",
  components: {}
};
</script>
