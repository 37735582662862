import Vue from "vue";
import Router from "vue-router";
import AppHeader from "src/pages/AppHeader";
import AppFooter from "src/pages/AppFooter";
import Landing from "src/pages/Landing.vue";
import CookiePolicy from "src/pages/CookiePolicy";

Vue.use(Router);

export default new Router({
  mode: 'history',
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter
      }
    },
    {
      path: "/cookie_policy",
      name: "cookiePolicy",
      components: {
          header: AppHeader,
          default: CookiePolicy,
          footer: AppFooter
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
