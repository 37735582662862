<template>
    <footer class="footer has-cards">
        <div class="container">
            <div class="row row-grid align-items-center my-md">
                <div class="col-lg-6">
                    <h3 class="text-primary font-weight-light mb-2">Interested in our projects?</h3>
<!--                    <h4 class="mb-0 font-weight-light">Please, feel free to contact us for any query!</h4>-->
                </div>
                <div class="col-lg-6 text-center btn-wrapper">
<!--                    <a target="_blank" rel="noopener" href=""-->
<!--                       class="btn btn-neutral btn-icon-only btn-twitter btn-round btn-lg" data-toggle="tooltip"-->
<!--                       data-original-title="Follow us">-->
<!--                        <i class="fa fa-twitter"></i>-->
<!--                    </a>-->
<!--                    <a target="_blank" rel="noopener" href=""-->
<!--                       class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg" data-toggle="tooltip"-->
<!--                       data-original-title="Like us">-->
<!--                        <i class="fa fa-facebook-square"></i>-->
<!--                    </a>-->


                    <a href="mailto:info@ndfltd.com" target="_blank" rel="noopener noreferrer"
                           class="btn btn-primary btn-icon">
                        <span class="btn-inner--icon">
                          <i class="fa fa-envelope mr-2"></i>
                        </span>
                        <span class="nav-link-inner--text">Contact us</span>
                    </a>

                </div>

                <div class="col-12 text-center">
                    <img src="./../assets/img/logo/logo_sm.png" alt="" width="30px" height="auto" class="d-inline">
                    <p class="description mb-0 d-inline pl-1">NDF Solutions LTD<br>
                        24 Tax Suite 137b Westlink House <br>
                        981 Great West Road, Brentford, United Kingdom, TW8 9DN<br>
                        Company number: 13719858</p>
                </div>
            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-6">
                    <div class="copyright">
                        &copy; NDF Solutions LTD 2021<span v-if="year !== 2021"> - {{ year }}</span>. {{ $t('common.right_reserved') }}
                    </div>
                </div>
                <div class="col-md-6">
                    <ul class="nav nav-footer justify-content-end">
<!--                        <li class="nav-item">-->
<!--                            <a href="" class="nav-link" rel="noopener">Privacy Policy</a>-->
<!--                        </li>-->

                        <li class="nav-item">
                          <router-link tag="a" class="nav-link" to="/cookie_policy">
                            Cookie Policy
                          </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'app-footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
<style>
</style>
